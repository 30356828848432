import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      website: "",
      message: "",
      mailSent: false,
      error: null,
    }
  }

  handleFormSubmit(event) {
    console.log(this.state)
  }

  render() {
    return (
      <Layout>
        <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
        </Helmet>
        <div className="contact-form-container">
          <form className="contact-form" method="post" action="../emailform.php">
            <h1>Contact Ronda</h1>
            <input type="text" name="_gotcha" style={{ display: "none" }} />
            <fieldset>
              <label>
                Name*
                <input
                  placeholder="Your name"
                  id="name"
                  name="name"
                  type="text"
                  tabIndex="1"
                  minLength="4"
                  required
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Email*
                <input
                  placeholder="Your Email Address"
                  id="email"
                  name="email"
                  type="email"
                  tabIndex="2"
                  minLength="5"
                  required
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Phone
                <input
                  placeholder="Your Phone Number"
                  id="phone"
                  name="phone"
                  type="tel"
                  tabIndex="3"
                  value={this.state.phone}
                  onChange={e => this.setState({ phone: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Website
                <input
                  placeholder="Your Web Site starts with http://"
                  id="website"
                  name="website"
                  type="url"
                  tabIndex="4"
                  value={this.state.website}
                  onChange={e => this.setState({ website: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Message*
                <textarea
                  placeholder="Type your Message Here...."
                  id="message"
                  name="message"
                  tabIndex="5"
                  required
                  value={this.state.message}
                  onChange={e => this.setState({ message: e.target.value })}
                ></textarea>
              </label>
              <h6 style={{ textAlign: "right", margin: 0 }}>*Required</h6>
            </fieldset>
            <fieldset><div className="g-recaptcha" data-sitekey="6LcqBlAUAAAAACDmNf7nrAtYTcTI0JSmWvTdxNRf" style={{margin:0, display:"flex", justifyContent:"center"}}></div></fieldset>
            <fieldset>
              <button
                name="submit"
                type="submit"
                id="contact-submit"
                data-submit="...Sending"
                onClick={e => this.handleFormSubmit(e)}
              >
                Submit
              </button>
            </fieldset>
            <div>
              {this.state.mailSent && (
                <div>Thank you. Your message was submitted.</div>
              )}
            </div>
          </form>
        </div>
      </Layout>
    )
  }
}
